import React from "react";
import styled from "styled-components";
// Components

// Assets
import OmniChannel from "src/assets/img/product/omni-channel.png"
import Brand from "src/assets/img/product/brand.png"
import Transfer from "src/assets/img/product/transfer.png"
import Review from "src/assets/img/product/review.png"

import ChatKnight from "src/assets/img/product/chat_knight.png"

export default function Chat() {
  return (
    <Wrapper id="technology" className="container">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo className="flexNullCenter">
            <img className="radius6" src={ChatKnight} alt="FrontApp" style={{height:"250px"}}/>
            <div style={{marginLeft: "30px"}}>
              <h1 className="font40 extraBold">Chat Knight</h1>
              <p className="font25">
                Turn customer engagement into your competitive advantages
              </p>
            </div>
          </HeaderInfo>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Omni-Channel with WebChat, SMS, WhatsApp and Social Media</h2>
              <p className="font20">
                Aissistant works with agent platform to offer consistent experience across all the channels to your customers
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={OmniChannel} alt="test" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Make your brand stand out of competition</h2>
              <p className="font20">
                90% users are tired of canned messages from the robotic bots. Aissistant offer you the power to create something really stands out of competition. 
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={Brand} alt="test" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Transfer to human smoothly</h2>
              <p className="font20">
                Aissistant automatically detects the needs for human help and initiate the hand-over smoothly. Because AI and person work on the same platform, the hand-over is frictionless. 
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={Transfer} alt="test" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Support both automated and draft modes</h2>
              <p className="font20">
                You can choose between automated and draft modes, whatever suites your needs. Usually people start with draft mode, and let it run automatically after built the confidence.
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={Review} alt="dual mode" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin-top: 150px;
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 10px 0;
  margin: 10px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 50px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddTextLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddTextRight = styled.div`
  position: relative;
  width: 40%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddImageLeft = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddImageRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 1% 1%;
  img {
    max-width: 100%;
    height: 350px;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
