import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import TestimonialSlider from "../Elements/TestimonialSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets


export default function Services() {
  return (
    <Wrapper id="services">
      <div className="whiteBg" style={{ padding: "50px 0" }}>
        <Wrapper className="container">
          <HeaderInfo>
            <p className="font30 extraBold">
              Users success
            </p>
          </HeaderInfo>
          <Wrapper className="container flexSpaceCenter">
            <UserCommentWrapper>
            <iframe style={{border: "none"}} src="https://cards.producthunt.com/cards/reviews/698290?v=1" width="100%" height="405" frameborder="0" scrolling="no" allowfullscreen></iframe>
            </UserCommentWrapper>
            <UserCommentWrapper>
            <iframe style={{border: "none"}} src="https://cards.producthunt.com/cards/reviews/687793?v=1" width="100%" height="405" frameborder="0" scrolling="no" allowfullscreen></iframe>
            </UserCommentWrapper>
            <UserCommentWrapper>
            <iframe style={{border: "none"}} src="https://cards.producthunt.com/cards/reviews/687952?v=1" width="100%" height="405" frameborder="0" scrolling="no" allowfullscreen></iframe>
            </UserCommentWrapper>
          </Wrapper>
        </Wrapper>
        <div className="container">
          <ClientSlider />
        </div>
        {/*
        <div className="container">
          <HeaderInfo>
            <h1 className="font30 extraBold" style={{ margin: "50px 0px 20px 0px" }}>Success Stories</h1>
            <p className="font20">
              Rapidly-growing SMBs
            </p>
          </HeaderInfo>
        </div>
        <div className="container">
          <WrapperBtn className="animate pointer" onClick={event => window.open("https://doc.aissist.io/en/cases/sunroom")}>
            <h3 className="font25 extraBold">{"Sales Lead Conversion"}</h3>
            <p className="font18" style={{ padding: "30px 0" }}>
              {"Human level conversation rate with fractional cost."}
            </p>
            <p className="font13 extraBold">{"click to learn more ..."}</p>
            <div className="flex">
              <p className="tag coralBg radius6 font13 extraBold" style={{ margin: "5px 5px 0 5px"}}>{"growth"}</p>
              <p className="tag coralBg radius6 font13 extraBold" style={{ margin: "5px 5px 0 5px"}}>{"scale"}</p>
              <p className="tag coralBg radius6 font13 extraBold" style={{ margin: "5px 5px 0 5px"}}>{"revenue"}</p>
              <p className="tag coralBg radius6 font13 extraBold" style={{ margin: "5px 5px 0 5px"}}>{"efficiency"}</p>
            </div>
          </WrapperBtn>
          <WrapperBtn className="animate pointer" onClick={event => window.open("https://doc.aissist.io/en/cases/edis")}>
            <h3 className="font25 extraBold">{"Customer Service"}</h3>
            <p className="font18" style={{ padding: "30px 0" }}>
              {"85% resolution rate across all deployed Aissistants"}
            </p>
            <p className="font13 extraBold">{"click to learn more ..."}</p>
            <div className="flex">
              <p className="tag coralBg radius6 font13 extraBold" style={{ margin: "5px 5px 0 5px"}}>{"productivity"}</p>
              <p className="tag coralBg radius6 font13 extraBold" style={{ margin: "5px 5px 0 5px"}}>{"cost"}</p>
              <p className="tag coralBg radius6 font13 extraBold" style={{ margin: "5px 5px 0 5px"}}>{"scale"}</p>
              <p className="tag coralBg radius6 font13 extraBold" style={{ margin: "5px 5px 0 5px"}}>{"CSAT"}</p>
            </div>
          </WrapperBtn>
        </div>
        */}
        <div className="container">
          {/*
          <TestimonialSlider />
          */}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const UserCommentWrapper = styled.div`
  width: 33%;
  padding: 2px 0;
  position: relative;
  @media (max-width: 960px) {
    width: 100%;
    margin: 10px;
    text-align: center;
  }
  @media (max-width: 560px) {
    width: 100%;
    margin: 10px;
  }
`;