
export function post(url, headers, params, payload, onSuccess, onError, data){

    const uri = url + (params != null ? encodeQueryParams(params) : "")

    // console.log("gateway post, url", url, "headers", headers, "params", params, "payload", payload)

    const result = fetch(uri, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            ...headers,
            // 'Access-Control-Allow-Origin:': "aissistant.ngrok.app",
            'content-type': 'application/json',
            Accept: 'application/json, text/plain, */*',
        },
        body: JSON.stringify(payload)
    }).then((response) =>{
        // console.log("raw response ==> ", response)
        if(response.status >= 400){
            if (onError){
                onError(response.text(), data,)
            }
        }else{
            // console.log("raw response ==> ", response)
            if(onSuccess){
                onSuccess(null, data)
            }
        }
    }, function(err){
        if (onError != null){
            onError(err, data)
        }
    })
}

function encodeQueryParams(params) {
    if (params != null && Object.keys(params).length > 0){
        const ret = [];
        for (let d in params){
            var value = params[d]
            if (value != null){
                if (typeof value === "object"){
                    value = JSON.stringify(params[d])
                }
                ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(value));
            }else{
                // ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(value));
            }
        }
        return ("?" + ret.join('&'));
    }else{
        return ""
    }
}