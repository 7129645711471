
import React, {useEffect, useState} from "react";
import styled from "styled-components";

import SmallButton from "src/components/Buttons/SmallButton";
import FullButton from "src/components/Buttons/FullButton";

import { post } from "src/components/api/gateway";
import ThumbUpImage from "src/assets/img/widget/thumb_up.jpg"
import ThumbDownImage from "src/assets/img/widget/thumb_down.png"

import { useZafClient } from "./ZendeskContext";

const GATEWAY_URL = "https://gateway.aissist.io/gateway/zendesk/widget"    // "https://gateway.aissist.io/gateway/zendesk/widget", "http://localhost:8082/gateway/zendesk/widget"


export default function ZendeskWidget () {

    const context = useZafClient()

    const [disabled, setDisabled] = useState({
        create_draft: false,
        translate_rephrase_send: false,
        translate: false,
        summarize: false,
        add_conv_example: false,
    })

    const [text, setText] = useState(null)
    const [rephrase_text, setRephraseText] = useState(null)
    const [instruction, setInstruction] = useState(null)

    const [message, setMessage] = useState({
        add_conv_example: null,
        translate_rephrase_send: null,
        create_draft: null,
        translate: null,
        summarize: null,
    })

    useEffect(() => {
        
    }, []);

    function onSuccess(resp, data){
        if (data && data.command){
            doSetMessage(data.command, {
                type: "Success",
                text: "Request successfully submitted!"
            })
        }
    }

    function onError(error, data){
        if (data && data.command){
            doSetMessage(data.command, {
                type: "Error",
                text: "Request submit failed! error= " + error
            })
        }
    }

    function doSetMessage(command, msg){
        if (command && msg){
            message[command] = msg
            setMessage(message)
        }
    }

    const sendToGateway = (params) => {
        if (params && params.command){
            if (params.command == "add_conv_example" && (params.text == null || params.text.trim() == "")){
                doSetMessage(params.command, {
                    type: "Error",
                    text: "the instruction can not be empty"
                })
                return
            }

            context.get([
                'ticket', 'currentUser'
            ]).then(function(data) {
                // console.log("recived data", data)
                if (data && data.ticket){
                    const payload = {
                        "ticket": data.ticket,
                        "currentUser": data.currentUser,
                    }

                    post(GATEWAY_URL, {}, params, payload, onSuccess, onError, params)
                    doSetMessage(params.command, {
                        type: "Success",
                        text: "Request submitted!"
                    })
                }else{
                    doSetMessage(params.command, {
                        type: "Error",
                        text: "Can not find valid conversation, try again later."
                    })
                }
            })
        }
    }

    const tempDisable = (name) => {
        // console.log("name", name)
        if (name){
            if (name == "add_conv_example"){
                setDisabled({
                    ... disabled,
                    add_conv_example: true
                })
                setTimeout(function(){
                    setDisabled({
                        ... disabled,
                        add_conv_example: false
                    });
                    clearMessage();
                }, 5000);
            }
            else if (name == "create_draft"){
                setDisabled({
                    ... disabled,
                    create_draft: true
                })
                setTimeout(function(){
                    setDisabled({
                        ... disabled,
                        create_draft: false
                    });
                    clearMessage();
                }, 5000);
            }
            else if (name == "translate_rephrase_send"){
                setDisabled({
                    ... disabled,
                    translate_rephrase_send: true
                })
                setTimeout(function(){
                    setDisabled({
                        ... disabled,
                        translate_rephrase_send: false
                    });
                    clearMessage();
                }, 5000);
            }
            else if (name == "translate"){
                setDisabled({
                    ... disabled,
                    translate: true
                })
                setTimeout(function(){
                    setDisabled({
                        ... disabled,
                        translate: false
                    });
                    clearMessage();
                }, 5000);
            }
            else if (name == "summarize"){
                setDisabled({
                    ... disabled,
                    summarize: true
                })
                setTimeout(function(){
                    setDisabled({
                        ... disabled,
                        summarize: false
                    });
                    clearMessage();
                }, 5000);
            }
        }
    }

    function clearMessage(){
        setMessage({
            add_conv_example: null,
            create_draft: null,
            translate: null,
            summarize: null,
        })
    }

    function getClassName(type) {
        if (type){
            if (type == "Success"){
                return "font15 greenColor"
            }
            else if (type == "Error"){
                return "font15 redColor"
            }
            else if (type == "Info"){
                return "font15 orangeColor"
            }
            else{
                return "font15"
            }
        }else{
            return "font15"
        }
    }

    return (
        <Wrapper id="technology" className="container">
            <div className="App">
                <h1 className="font25 greenColor extraBold"><a className="greenColor" href="https://www.aissist.io" target="_blank">Aissist.io</a></h1>
            </div>
            <div style={{ paddingBottom: "10px" }} />
            <div className="container" style={{ paddingTop: "0px" }}>
                <p className="font20">
                    Help Aissistant continuously learn 
                </p>
                <div className="container flex flexColumn">
                    <textarea
                        required 
                        rows="3" 
                        // cols="20" 
                        type="text" 
                        id="input" 
                        name="(Required) instruction"
                        label="(Required) instruction"
                        placeholder="(Required) Instruction for similar situation, e.g., tell users all shipping will have 5 days delay due to holidays ..." 
                        className="font15" 
                        value={instruction ? instruction : ""} 
                        onChange={(e) => setInstruction(e.target.value)}/>
                    <SmallButton
                        id="front-widget-add_conv_example-button"  
                        disabled={disabled.add_conv_example || ! instruction}
                        title={"Instruct AI"}
                        action={event => {
                            tempDisable("add_conv_example")
                            const params = {
                                command: "add_conv_example",
                                text: instruction
                            }
                            clearMessage()
                            sendToGateway(params)
                            setInstruction(null)
                        }}>
                    </SmallButton>
                    {message.add_conv_example && message.add_conv_example.text ? 
                    <label className={getClassName(message.add_conv_example.type)}>
                        {message.add_conv_example.text}
                    </label>
                    : null}
                </div>
            </div>
            <div style={{ paddingBottom: "30px" }} />
            <div className="container" style={{ paddingTop: "0px" }}>
                <p className="font18 bold">
                    <a className="greenColor" href="https://doc.aissist.io/gateways/zendesk" target="_blank">Co-Pilot</a> empowered by Gen AI! 
                </p>
                <div style={{ paddingBottom: "10px" }} />
                <div className="container flex flexColumn">
                    <div className="flex flexColumn">
                        <label className="font15">Translate what user said</label>
                        <SmallButton
                            id="front-widget-translate-button" 
                            disabled={disabled.translate}
                            title={"Translate"}
                            action={event => {
                                tempDisable("translate")
                                const params = {
                                    command: "translate",
                                }
                                clearMessage()
                                sendToGateway(params)
                            }}>
                        </SmallButton>
                        {message.translate && message.translate.text ? 
                        <label className={getClassName(message.translate.type)}>
                            {message.translate.text}
                        </label>
                    : null}
                    </div>
                    <div style={{ paddingBottom: "10px" }} />
                    <div className="flex flexColumn">
                        <label className="font15">Summarize the conversation</label>
                        <SmallButton
                            id="front-widget-summarize-button"  
                            disabled={disabled.summarize}
                            title={"Summarize"}
                            action={event => {
                                tempDisable("summarize")
                                const params = {
                                    command: "summarize"
                                }
                                clearMessage()
                                sendToGateway(params)
                            }}>
                        </SmallButton>
                        {message.summarize && message.summarize.text ? 
                        <label className={getClassName(message.summarize.type)}>
                            {message.summarize.text}
                        </label>
                        : null}
                    </div>
                    <div style={{ paddingBottom: "10px" }} />
                    <div className="flex flexColumn">
                        <label className="font15">Translate / rephrase then send</label>
                        <textarea
                            required 
                            rows="3" 
                            // cols="20" 
                            type="text" 
                            id="input" 
                            name="(Required) input"
                            label="(Required) input"
                            placeholder="(Required) e.g., can you give me more specifics about your issue?" 
                            className="font15" 
                            value={rephrase_text ? rephrase_text : ""} 
                            onChange={(e) => setRephraseText(e.target.value)}/>
                        <FullButton
                            id="front-widget-translate_rephrase_send-button"  
                            disabled={disabled.translate_rephrase_send || ! rephrase_text}
                            title={"Translate / rephrase then send"}
                            action={event => {
                                tempDisable("translate_rephrase_send")
                                const params = {
                                    command: "translate_rephrase_send",
                                    text: rephrase_text
                                }
                                clearMessage()
                                sendToGateway(params)
                                setRephraseText(null)
                            }}>
                        </FullButton>
                        {message.translate_rephrase_send && message.translate_rephrase_send.text ? 
                        <label className={getClassName(message.translate_rephrase_send.type)}>
                            {message.translate_rephrase_send.text}
                        </label>
                        : null}
                    </div>
                    <div style={{ paddingBottom: "10px" }} />
                    <div className="flex flexColumn">
                        <label className="font15">Create Draft</label>
                        <textarea 
                            rows="3" 
                            // cols="20" 
                            type="text" 
                            id="input" 
                            name="(Optional) input or instruction"
                            label="(Optional) input or instruction"  
                            placeholder="e.g., tell the user there will be 5 days delay due to holidays ..."
                            className="font15" 
                            value={text ? text : ""} 
                            onChange={(e) => setText(e.target.value)}/>
                        <SmallButton
                            id="front-widget-create_draft-button"  
                            disabled={disabled.create_draft}
                            title={"Create Draft"}
                            action={event => {
                                tempDisable("create_draft")
                                const params = {
                                    command: "create_draft",
                                    text: text
                                }
                                clearMessage()
                                sendToGateway(params)
                                setText(null)
                            }}>
                        </SmallButton>
                        {message.create_draft && message.create_draft.text ? 
                        <label className={getClassName(message.create_draft.type)}>
                            {message.create_draft.text}
                        </label>
                        : null}
                    </div>
                </div>
            </div>
            <div className="container" style={{ paddingTop: "10px" }}>
                <p className="font18">
                    Login <a href="https://console.aissist.io" target="_blank" className="greenColor font18">console.aissist.io</a>
                </p>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.section`
  margin-top: 10px;
  width: 100%;

  @media (max-width: 1160px) {
    padding: 10px 0 10px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 10px 0;
    margin: 10px 0 0px 0;
  }
`;