import React from "react";
import styled from "styled-components";

// Assets
import CheckMark from "../../assets/svg/Checkmark";

export default function PricingTable({ heads, rowHeads, columns }) {

  return (
    <Wrapper className="whiteBg radius8 shadow">
        <table>
            <tr>
                <th></th>
                {heads.map((head, index) => (
                    <th width="25%">
                        <TableBox>
                            <div style={{ margin: "30px 0" }}>
                                <h4 className="font30 extraBold greenColor">{head.name}</h4>
                                <p className="font13">{head.desc}</p>
                            </div>
                        </TableBox>
                    </th>
                ))}
            </tr>
            {rowHeads.map((rowHead, rowIndex) => (
                <tr>
                    <td width="25%">
                        <h6 className="font18">{rowHead.name}</h6>
                        <p className="font13">
                            {rowHead.desc}
                        </p>
                        {rowHead.link ? <a href={rowHead.link} className="font13 greenColor" target="_blank_"> learn more ...</a> : null}
                    </td>
                    {columns.map((column, columnIndex) => (
                        <td>
                            <div className="flexNullCenter" style={{ margin: "15px 0" }} key={rowIndex + "." + columnIndex}>
                                <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                                    {column[rowIndex].checked ? 
                                    <div style={{ minWidth: "20px" }}>
                                    <CheckMark />
                                    </div>
                                    :null}
                                    <p className="font16">
                                        {column[rowIndex].text}
                                    </p>
                                </div>
                            </div>
                        </td>
                    ))}
                </tr>
            ))}
        </table> 
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;

const TableBox = styled.div`
  padding: 10px;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;
