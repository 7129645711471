import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

export default function ClientSlider({clients}) {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 2,
    arrows: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        {clients ? clients.map(client => {
          return <LogoWrapper className="flexCenter">
            <ImgStyle src={client.img} alt={client.name} />
            <h6 className="font15">
              {client.name} 
            </h6>
            <p className="font15">
              {client.category} 
            </p>
          </LogoWrapper>
        }) : null}
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 80%;
  aspect-ratio: 1;
  padding: 10%;
`;