import React from "react";
import styled from "styled-components";
// Components
// Assets
import ServiceBox from "../Elements/ServiceBox";

import AissistArchitect from "../../assets/img/product/aissist_architect.png"
import AissistCreateWorkspace from "../../assets/img/product/aissist_create_workspace.gif"
import Aissistant from "../../assets/img/product/aissistant.png"
import Assets from "../../assets/img/product/assets.png"
import Workflow from "src/assets/img/product/workflow.png"
import NoRules from "../../assets/img/product/no_rules.png"
import DashboardAnalysis from "../../assets/img/product/dashboard_analysis.png"
import LargeLanguageModel from "../../assets/img/product/large_language_model.png"
import Onboard from "../../assets/img/product/onboard.png"

export default function Technology() {
  return (
    <Wrapper id="technology" className="container">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Technology</h1>
            <p className="font25">
              Generative AI tailored to every fast-growing SMB
            </p>
            <p className="font20">
              High resolution rate, human-like, easy to set up & manage, and pennies to use
            </p>
          </HeaderInfo>
        </div>
      </div>
      <div className="whiteBg">
        <div className="container">
          <Advertising className="flexColumn">
            <div className="container">
              <h1 className="font25 extraBold">10 mins to create your Business Co-Pilot today!</h1>
              <img className="radius6" src={Onboard} alt="Onboard" />
            </div>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Seamlessly integrate with agent platforms</h2>
              <p className="font20">
                Aissistant enables the best human-AI synergy and frictionless transformation! Streamline your customer engagement and improve your team productivity, today!
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={Workflow} alt="test" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddImageLeft>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={AissistArchitect} alt="Smart Tagger" />
              </ImgWrapper>
            </AddImageLeft>
            <AddTextRight>
              <h2 className="font30 extraBold">Turn your assets into superb service and business growth!</h2>
              <p className="font20">
                Aissistant automatically converts your assets (Doc, Website, Information systems, etc) into intelligence to drive up both service and sales.
                <a href="https://doc.aissist.io" className="greenColor font18" target="_blank_"> Learn more ...</a>
              </p>
            </AddTextRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Focus on the foundamentals!</h2>
              <p className="font20">
                Instead of a long list of fancy features, we focus on the three foundamental elements of customer engagement, and do them extremely well. By combining those three, you can accomplish anything you ever wanted.
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={Aissistant} alt="test" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddImageLeft>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={Assets} alt="Large Language Model" />
              </ImgWrapper>
            </AddImageLeft>
            <AddTextRight>
              <h2 className="font30 extraBold">Turn your assets into productivity, instantly!</h2>
              <p className="font20 greenColor extraBold">
                Why pay $$$$ and wait for weeks?
              </p>
              <p className="font18">
                In 10 minutes and at NO cost, Aissist.io turns your assets (website, Google Doc/Spreadsheet, logs, database) into intelligence that boosts productivity and reduces cost instantly. 
              </p>
            </AddTextRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">True Generative AI! No rules / intents!</h2>
              <p className="font20">
                Puzzled on why so many bots work on intents? In Aissist.io, we strongly believe AI != tedious rules! Therefore, we insist on NO rules or intents, NO drag-and-drop, No diagrams. You describe what you want and prescribe what AI should know, let AI handles the rest for you.
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={LargeLanguageModel} alt="Turn your assets into productivity" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddImageLeft>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={DashboardAnalysis} alt="Large Language Model" />
              </ImgWrapper>
            </AddImageLeft>
            <AddTextRight>
              <h2 className="font30 extraBold">Generate insights automatically!</h2>
              <p className="font20">
                Getting lost in piles of customer engagement? Aissistant helps you generate insight whatever the way you want it, just tell AI. 
              </p>
            </AddTextRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin-top: 150px;
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 30px 0;
  margin: 30px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 50px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddTextLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddTextRight = styled.div`
  position: relative;
  width: 40%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddImageLeft = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddImageRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 1% 1%;
  img {
    max-width: 100%;
    height: 350px;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
