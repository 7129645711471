import React from "react";
import styled from "styled-components";
// Components
import VendorSlider from "../Elements/VendorSlider";

// Assets
import FrontApp from "../../assets/img/vendors/frontapp.png"
import Gorgias from "../../assets/img/vendors/gorgias.png";
import Kustomer from "../../assets/img/vendors/kustomer.webp";
import Intercom from "../../assets/img/vendors/intercom.png"
import Salesforce from "../../assets/img/vendors/salesforce.jpg";
import Zendesk from "../../assets/img/vendors/zendesk.png";
import EDesk from "../../assets/img/vendors/edesk.png"
import Wechat from "../../assets/img/vendors/wechat.jpeg";
import Slack from "../../assets/img/vendors/slack.png";
import Tiktok from "../../assets/img/vendors/tiktok.png";
import Twilio from "../../assets/img/vendors/twilio.png";
import Freshdesk from "../../assets/img/vendors/freshdesk.png";

import WWW from "../../assets/img/vendors/website.png"
import GoogleDoc from "../../assets/img/vendors/google_doc_sheet.png"
import Shopify from "../../assets/img/vendors/shopify.png";
import Hubspot from "../../assets/img/vendors/hubspot.png";
import Pipedive from "../../assets/img/vendors/pipedive.png";
import Calendly from "../../assets/img/vendors/calendly.png";
import Postgresql from "../../assets/img/vendors/postgresql.png";

import OpenAI from "../../assets/img/vendors/openai_logo.png";
import Anthropic from "../../assets/img/vendors/anthropic.png"

const primary_partners = [
  {
    name: "Open AI",
    category: "Tech",
    img: OpenAI
  },
  {
    name: "Anthropic",
    category: "Tech",
    img: Anthropic
  },
  {
    name: "Front",
    category: "Agent Platform",
    img: FrontApp
  },
  {
    name: "Gorgias",
    category: "Agent Platform",
    img: Gorgias
  },
  {
    name: "Intercom",
    category: "Agent Platform",
    img: Intercom
  },
  {
    name: "Zendesk",
    category: "Agent Platform",
    img: Zendesk
  },
  {
    name: "Hubspot",
    category: "Agent Platform",
    img: Hubspot
  },
]

const tech_partners = [
  {
    name: "Open AI",
    category: "Tech",
    img: OpenAI
  },
  {
    name: "Anthropic",
    category: "Tech",
    img: Anthropic
  },
]

const agent_platform_partners = [
  {
    name: "Front",
    category: "Agent Platform",
    img: FrontApp
  },
  {
    name: "Gorgias",
    category: "Agent Platform",
    img: Gorgias
  },
  {
    name: "Intercom",
    category: "Agent Platform",
    img: Intercom
  },
  {
    name: "Kustomer",
    category: "Agent Platform",
    img: Kustomer
  },
  {
    name: "Zendesk",
    category: "Agent Platform",
    img: Zendesk
  },
  {
    name: "EDesk",
    category: "Agent Platform",
    img: EDesk
  },
  {
    name: "Salesforce",
    category: "Agent Platform",
    img: Salesforce
  },
]

const integration_partners = [
  {
    name: "GoogleDoc",
    category: "Knowledge Base",
    img: GoogleDoc
  },
  {
    name: "WWW",
    category: "Knowledge Base",
    img: WWW
  },
  {
    name: "Shopify",
    category: "Inventory & Order",
    img: Shopify
  },
  {
    name: "Hubspot",
    category: "CRM",
    img: Hubspot
  },
  {
    name: "Pipedive",
    category: "CRM",
    img: Pipedive
  },
  {
    name: "Calendly",
    category: "Calendar",
    img: Calendly
  },
  {
    name: "Postgresql",
    category: "Database",
    img: Postgresql
  },
]

export default function Services() {
  return (
    <Wrapper id="partners">
      <div className="whiteBg" style={{ padding: "100px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font30 extraBold">Partners</h1>
            <p className="font18">
              Assistant works on your agent platform, functions just like a human and integrates seamlessly with your existing processes.
            </p>
            <p className="font15">
              <a className="greenColor" href="/contact">Contact us</a> with your interest in specific integrations!
            </p>
          </HeaderInfo>
        </div>
        <div style={{ padding: "20px 0" }}></div>
        <div className="container">
          <VendorSlider clients={primary_partners}/>
        </div>
        {/*
        <div className="container">
          <h5 className="font18">Tech Partners</h5>
          <VendorSlider clients={tech_partners}/>
        </div>
        <div className="container">
          <h5 className="font18">Platform Panters</h5>
          <VendorSlider clients={agent_platform_partners}/>
        </div>
        <div className="container">
          <h5 className="font18">System Panters</h5>
          <VendorSlider clients={integration_partners}/>
        </div>
        */}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 100%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;