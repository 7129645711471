import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
// Components
import Sidebar from "src/components/Nav/Sidebar";
import Backdrop from "src/components/Elements/Backdrop";
// Assets
import Logo from "src/assets/svg/Logo";
import BurgerIcon from "src/assets/svg/BurgerIcon";

import FrontApp from "src/assets/img/vendors/frontapp.png";
import Gorgias from "src/assets/img/vendors/gorgias.png";
import AutoPilot from "src/assets/img/product/auto_pilot.png"
import Email from "src/assets/img/product/email.png";
import Chat from "src/assets/img/product/chat.jpeg";
import Sales from "src/assets/img/product/sales.png";
import Down from "src/assets/img/down.png"

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  const [openMenuName, setOpenMenuName] = React.useState(null);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);


  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="/#home" smooth={true}>
            <Logo />
            <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
              
            </h1>
          </Link>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            {/*
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="/#home" spy={true} smooth={true} offset={-80} onClick={event => setOpenMenuName(null)}>
                Home
              </Link>
            </li>
            */}
            {/*
            <div className="semiBold font15 pointer dropdown">
              <Link class="dropbtn" style={{ padding: "10px 15px" }} spy={true} smooth={true} offset={-80} onClick={event => {openMenuName == "partners" ? setOpenMenuName(null) : setOpenMenuName("partners")}}>Partners<SmImgStyle src={Down} /></Link>
              {openMenuName == "partners" ?
              <div class="dropdown-content">
                <a activeClass="active" spy={true} smooth={true} offset={-80} style={{ padding: "10px 15px" }} href="/partners/frontapp"><ImgStyle src={FrontApp} />FrontApp</a>
                <a activeClass="active" spy={true} smooth={true} offset={-80} style={{ padding: "10px 15px" }} href="/partners/gorgias"><ImgStyle src={Gorgias} />Gorgias</a>
              </div>
              : null}
            </div>
            */}
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="/technology" spy={true} smooth={true} offset={-80} onClick={event => setOpenMenuName(null)}>
                Technology
              </Link>
            </li>
            {/*
            <div className="semiBold font15 pointer dropdown">
              <Link class="dropbtn" style={{ padding: "10px 15px" }} spy={true} smooth={true} offset={-80} onClick={event => {openMenuName == "products" ? setOpenMenuName(null) : setOpenMenuName("products")}}>Use Cases<SmImgStyle src={Down} /></Link>
              {openMenuName == "products" ?
              <div class="dropdown-content">
                <a activeClass="active" spy={true} smooth={true} offset={-80} style={{ padding: "10px 15px" }} href="/products/pilot_mode"><ImgStyle src={AutoPilot} />Auto-Pilot and Co-Pilot</a>
                <a activeClass="active" spy={true} smooth={true} offset={-80} style={{ padding: "10px 15px" }} href="/products/email"><ImgStyle src={Email} />Email Ninja</a>
                <a activeClass="active" spy={true} smooth={true} offset={-80} style={{ padding: "10px 15px" }} href="/products/chat"><ImgStyle src={Chat} />Chat Knight</a>
                <a activeClass="active" spy={true} smooth={true} offset={-80} style={{ padding: "10px 15px" }} href="/products/sales"><ImgStyle src={Sales} />Sales ELF</a>
              </div>
              : null}
            </div>
            */}
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="/#pricing" spy={true} smooth={true} offset={-80} onClick={event => setOpenMenuName(null)}>
                Pricing
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <a activeClass="active" spy={true} smooth={true} offset={-80} style={{ padding: "10px 15px" }} href="https://doc.aissist.io" target="_blank">Documents</a>
            </li>
            {/*
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="/collaborators" spy={true} smooth={true} offset={-80} onClick={event => setOpenMenuName(null)}>
                Collaborators
              </Link>
            </li>
            */}
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="/contact" spy={true} smooth={true} offset={-80} onClick={event => setOpenMenuName(null)}>
                Request Demo
              </Link>
            </li>
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            {/*
            <li className="semiBold font15 pointer">
              <a href="https://doc.aissist.io" style={{ padding: "10px 30px 10px 0" }} target="_blank_">
                Documents
              </a>
            </li>
            */}
            <li className="semiBold font15 pointer">
              <a href="https://console.aissist.io/login" style={{ padding: "10px 30px 10px 0" }} target="_blank_">
                Sign in
              </a>
            </li>
            <li className="semiBold font15 pointer flexCenter">
              <a href="https://console.aissist.io/register" className="radius8 lightBg" style={{ padding: "10px 15px" }} target="_blank_">
                Sign up
              </a>
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;

const ImgStyle = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

const SmImgStyle = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 2px;
`;

