import React from "react";
import {BrowserRouter, Routes, Route } from "react-router-dom" 
// Screens
import TopNavbar from "./components/Nav/TopNavbar";
import Footer from "./components/Sections/Footer"
import Landing from "./screens/Landing.jsx";
import Technology from "./components/Sections/Technology";
import Contact from "./components/Sections/Contact";
import Collaborators from "./components/Sections/Collaborators";
import Blog from "./components/Sections/Blog";

// partners
import FrontApp from "./components/Sections/partners/frontapp";
import Gorgias from './components/Sections/partners/gorgias'

// products
import PilotMode from "./components/Sections/products/pilot_mode";
import Email from './components/Sections/products/email'
import Chat from './components/Sections/products/chat'
import Sales from './components/Sections/products/sales'

// Widgets
import { FrontContextProvider } from "./components/Providers/Front/FrontContext";
import FrontWidget from "./components/Providers/Front/FrontWidget";
import ZendeskWidget from "./components/Providers/Zendesk/ZendeskWidget";

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<div><TopNavbar /><Landing /><Footer /></div>}/>
          <Route path="/technology" element={<div><TopNavbar /><Technology /><Footer /></div>}/>
          <Route path="/contact" element={<div><TopNavbar /><Contact /><Footer /></div>} />
          <Route path="/collaborators" element={<div><TopNavbar /><Collaborators /><Footer /></div>}/>
          <Route path="/news" element={<div><TopNavbar /><Blog/><Footer /></div>} />
          <Route path="/partners/frontapp" element={<div><TopNavbar /><FrontApp /><Footer /></div>} />
          <Route path="/partners/gorgias" element={<div><TopNavbar /><Gorgias /><Footer /></div>} />
          <Route path="/products/pilot_mode" element={<div><TopNavbar /><PilotMode /><Footer /></div>} />
          <Route path="/products/email" element={<div><TopNavbar /><Email /><Footer /></div>} />
          <Route path="/products/chat" element={<div><TopNavbar /><Chat /><Footer /></div>} />
          <Route path="/products/sales" element={<div><TopNavbar /><Sales /><Footer /></div>} />

          {/* widgets */}
          <Route path="/widget/front" element={<React.StrictMode> <FrontContextProvider><FrontWidget/></FrontContextProvider></React.StrictMode>}/>
          <Route path="/widget/zendesk" element={<ZendeskWidget/>}/>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

