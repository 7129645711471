import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTableNew";
import CheckMark from "../../assets/svg/Checkmark";

const HEADS = [
  {
    name: "FREE",
    desc: "FREE, try-out"
  },
  {
    name: "PRO",
    desc: "Value package for SMB."
  },
  {
    name: "Enterprise",
    desc: "Full service (cotact sales)"
  },
]

const ROWHEADS = [
  {
    name: "Base Engine",
    desc: "perfect to handle simple FAQs, demo or production",
  },
  {
    name: "Advanced Engine",
    desc: "enterprise grade, optimized for complex scenarios and sentimental situations",
  },
  {
    name: "Multi-Modal",
    desc: "process and interact with image and video, only available with advanced engine"
  },
  {
    name: "Agent Platform Integration",
    desc: "seamless integration with the agent platforms",
    link: "https://doc.aissist.io/gateways"
  },
  {
    name: "Composite Engine",
    desc: "the proprietary engine that gets the best answer from ocean of information."
  },
  {
    name: "Auto-Pilot and Co-Pilot",
    desc: "generate responds or drafts",
    link: "https://doc.aissist.io/tutorial/auto-pilot-auto-draft-co-pilot",
  },
  {
    name: "Smart Tagging",
    desc: "intelligently generate tags from conversation",
    link: "https://doc.aissist.io/tutorial/create-smart-tagger"
  },
  {
    name: "Smart Handover",
    desc: "summarize the action items when hand over to human"
  },
  {
    name: "Smart Action",
    desc: "connect to any system you have: CRM, inventory, order, and database, etc.",
    link: "https://doc.aissist.io/tutorial/create-smart-action",
  },
  {
    name: "Smart Insight",
    desc: "generate anlaysis, insight and report on conversations, know what happened in the field.",
  },
  {
    name: "No Aissist Ads",
    desc: "no Aissist footnote at the end of message"
  },
  {
    name: "Customization",
    desc: "full service (AI design, cusustomized integration, etc)"
  }
]

const COLUMNS = [
  [
    {
      text: "100 FREE AI Interactions per month when used as deployed; Unlimited usage with Aissist.io console. "
    },
    {
      text: "Not Available"
    },
    {
      checked: false,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: false,
      text: null
    },
    {
      checked: false,
      text: null
    },
    {
      checked: false,
      text: null
    },
    {
      text: null
    },
    {
      text: null
    }
  ],
  [
    {
      text: "(Available but not recommended) $0.05 per AI Interaction"
    },
    {
      text: "(Recommnded) $0.15 per AI Interaction, for the high resolution and conversion rate"
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      text: null
    }
  ],
  [
    {
      text: "Available but not recommended"
    },
    {
      text: "Flat rate available with contract (contact sales)"
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    },
    {
      checked: true,
      text: null
    }
  ]
]

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Pricing</h1>
            <p className="font20">
              As low as $0.05 per AI interaction, no hidden fee, pay-as-you-use!
            </p>
            <p className="font15">
              Contact sales for flat rate
              <a href="contact" className="font20 extraBold radius8 lightBg greenColor" style={{ padding: "10px 15px" }} target="_blank_">Request Demo</a>
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <PricingTable
              heads={HEADS}
              rowHeads={ROWHEADS}
              columns={COLUMNS}
            />
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

