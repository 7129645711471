import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import Logo from "src/assets/svg/Logo";

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
              <Logo />
            </Link>
            <div className="flexColumn">
              <li className="semiBold font15 pointer">
                <a href="/products/email" className="whiteColor font15">Email Ninja</a>
              </li>
              <li className="semiBold font15 pointer">
                <a href="/products/chat" className="whiteColor font15">Chat Knight</a>
              </li>
              <li className="semiBold font15 pointer">
                <a href="/products/sales" className="whiteColor font15">Sales ELF</a>
              </li>
            </div>
            <div className="flexColumn">
              <li className="semiBold font15 pointer">
                <a activeClass="active" href="https://doc.aissist.io/tutorial/auto-pilot-auto-draft-co-pilot" className="whiteColor font15" target="_blank">Auto-Pilot vs. Co-Pilot</a>
              </li>
              <li className="semiBold font15 pointer">
                <a href="/collaborators" className="whiteColor font15">Collaborators Program</a>
              </li>
              <li className="semiBold font15 pointer">
                <a href="/contact" className="whiteColor font15">Contact us</a>
              </li>
              {/*
              <li className="semiBold font15 pointer">
                <a href="/news" className="whiteColor font15">News</a>
              </li>
              */}
            </div>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} - <span className="greenColor font13">Aissist</span> All Right Reserved
            </StyleP>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 960px) {
    margin: 20px 0;
  }
`;