import React from "react";
import styled from "styled-components";
// Components

// Assets
import SalesConversation from "src/assets/img/product/sales_conversation.png"
import Tagging from "src/assets/img/product/tagging.png"
import Handover from "src/assets/img/product/handover.png"
import Summarize from "src/assets/img/product/summarize.png"

import SalesElf from "src/assets/img/product/sales_elf.png"

export default function Sakes() {
  return (
    <Wrapper id="technology" className="container">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo className="flexNullCenter">
            <img className="radius6" src={SalesElf} alt="FrontApp" style={{height:"250px"}}/>
            <div style={{marginLeft: "30px"}}>
              <h1 className="font40 extraBold">Sales ELF</h1>
              <p className="font25">
                Scale your sales effectively and cost efficiently
              </p>
            </div>
          </HeaderInfo>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Conversion rate at human parity, proved!</h2>
              <p className="font20">
                Aissistant have been used by multiples users in sales lead generation and qualification, and proved to reach human performance, with fractional cost and scalability. 
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={SalesConversation} alt="test" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Auto tag the candidates, never miss a lead!</h2>
              <p className="font20">
                Using Aissistant "smart tagger", you can tag the leads whenever you want it and whatever you want it. Just describe what you need and let the Aissistant handle the rest. 
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={Tagging} alt="test" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Auto detect the need to hand over to human team</h2>
              <p className="font20">
                Aissistant is equipped with the advanced technology to automatically detect the needs for human involvement and hand the engagement to human in frictionless manner.  
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={Handover} alt="test" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Auto summarize to streamline the process</h2>
              <p className="font20">
                Aissistant automatically summarize the context and suggest the next steps to bring human team up to the speed quickly.
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={Summarize} alt="test" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin-top: 150px;
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 10px 0;
  margin: 10px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 50px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddTextLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddTextRight = styled.div`
  position: relative;
  width: 40%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddImageLeft = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddImageRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 1% 1%;
  img {
    max-width: 100%;
    height: 350px;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
