import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import EdisGlobalLogo from "../../assets/img/clients/edis_global.png"
import SunroomLeasingLog from "../../assets/img/clients/sunroom_leasing.png"
import EveryworksLog from "../../assets/img/clients/everyworks.png"
import BlueCarrotEvent from "../../assets/img/clients/blue_carrot_event.png"
import ByteTechnologyLogo from "../../assets/img/clients/byte_technology.png"
import GroundPackagingLogo from "../../assets/img/clients/grounded_packaging.jpg"
import GuidlyLogo from '../../assets/img/clients/guidely.png'

export default function ClientSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={EdisGlobalLogo} alt="Edis Global" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={SunroomLeasingLog} alt="Sunroom Leasing" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={GuidlyLogo} alt="Guidely" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={EveryworksLog} alt="Everyworks" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={BlueCarrotEvent} alt="Blue Carrot Event" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ByteTechnologyLogo} alt="Byte Technology" />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={GroundPackagingLogo} alt="Grounded Packaging" />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 100px;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;