import React from "react";
import { useState, useEffect } from 'react';
import styled from "styled-components";
// Assets
import { sendEmail } from "../Elements/SendEmail";

export default function Contact() {

  const [values, setValues] = useState({
    fname: "",
    email: "",
    subject: "",
    message: ""
  })

  const doSendEmail = () => {
    if (values.email && values.message){
      sendEmail(
        {
          from_name: values.fname,
          from_email: values.email,
          from_phone: null,
          project_name: null,
          project_id: null,
          title: values.subject,
          message: values.message
        }, 
        null,
        onSendEmailSuccess, 
        onSendEmailError
        ); 
      setValues({
        ...values,
        subject: "",
        message: ""
      });
    }else{
      alert("must have valid email and message");
    }
  }

  function onSendEmailSuccess(text){
    alert("message successfully sent, and our sales team to be with you shortly! '" + text + "'")
  }

  function onSendEmailError(error){
    alert("there is some error of sending message, please try it later! '" + error + "'")
  }

  return (
    <Wrapper id="contact" className="container flexSpaceCenter">
      <div className="whiteBg">
        <div className="container row">
          <LeftScreen>
            <HeaderInfo>
              <h1 className="font30 extraBold">Request Consultation</h1>
              <p className="font18">
                Aissistant is a SaaS platform,<a href="https://console.aissist.io/register" className="radius8 greenColor" style={{ padding: "10px 15px" }} target="_blank_">SIGN UP</a>today and try immediately! 
              </p>
              <p className="font18">
                Any questions, please let us know below or via <a href="mailto: sales@aissist.io" className="greenColor font18">sales@aissist.io</a>
              </p>
            </HeaderInfo>
            <div className="row" style={{ paddingBottom: "30px" }}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <Form>
                  <label className="font18">First name:</label>
                  <input type="text" id="fname" name="fname" className="font20 extraBold" value={values.fname} onChange={(e) => setValues({...values, fname: e.target.value})}/>
                  <label className="font18">Email:</label>
                  <input type="text" id="email" name="email" className="font20 extraBold" value={values.email} onChange={(e) => setValues({...values, email: e.target.value})}/>
                  <label className="font18">Subject:</label>
                  <input type="text" id="subject" name="subject" className="font18" value={values.subject} onChange={(e) => setValues({...values, subject: e.target.value})}/>
                  <textarea rows="4" cols="50" type="text" id="message" name="message" className="font18" value={values.message} onChange={(e) => setValues({...values, message: e.target.value})}/>
                </Form>
                <SumbitWrapper className="flex">
                  <ButtonInput 
                    type="submit" 
                    value="Send" 
                    onClick={doSendEmail}
                    className="pointer animate radius8 font18" 
                    style={{ maxWidth: "120px" }} 
                    >
                  </ButtonInput>
                </SumbitWrapper>
              </div>
            </div>
          </LeftScreen>
          <RightScreen>
            <HeaderInfo>
              <h1 className="font30 extraBold">Schedule demo</h1>
              <p className="font18">
                If you don't see the calendar below, please reload the page. Note: the calendar might be in Pacific Time. <a href="https://calendly.com/sales-aissist/30min" className="greenColor font18" target="_blank_">https://calendly.com/sales-aissist/30min</a>
              </p>
            </HeaderInfo>
            <div class="calendly-inline-widget" data-url="https://calendly.com/sales-aissist/30min" style={{minWidth: "320px", height: "700px"}}></div>
          </RightScreen>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin-top: 50px;
  width: 100%;
`;
const LeftScreen = styled.div`
  width: 50%;
  padding: 20px;
  @media (max-width: 860px) {
    width: 100%;
  }
`
const RightScreen = styled.div`
  width: 50%;
  padding: 20px;
  @media (max-width: 860px) {
    width: 100%;
  }
`
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #048219;
  background-color: #048219;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #f57c00;
    border: 1px solid #f57c00;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
