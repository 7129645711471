import React from "react";
// Sections
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Partners from "../components/Sections/Partners";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Customers from "../components/Sections/Customers"
import ConverseWidget from "../components/Elements/ConverseWidget";

export default function Landing() {

  const queryParameters = new URLSearchParams(window.location.search)
  const workspaceId = queryParameters.get("ws_id")
  const title = queryParameters.get("title")

  return (
    <>
      <Header />
      <Partners />
      <Customers />
      <Pricing />
      {/*
      <ConverseWidget
        chatId="Aissist-demo"
        title={title ? title : "Aissist Demo"}
        userId={Math.random().toString(16).slice(2)}
        workspaceId={workspaceId}
        endpointId="aissist_demo_www"
      />
      */}
    </>
  );
}


