import { useEffect, useState } from "react"

let zafClient = null;

export function useZafClient() {

    const [client, setClient] = useState(zafClient)

    useEffect(() => {
        if (! client ) {
            zafClient = window.ZAFClient.init();
            setClient(zafClient);
            if(zafClient) {
                zafClient.invoke("resize", {width: "100%", height: "50vh"});
            }
        }
    }, [client])

    return client

}