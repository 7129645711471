import React from "react";
import { useState, useEffect } from 'react';
import styled from "styled-components";
// Assets

import CollaboratorsImage from "src/assets/img/product/collaborators.png"

export default function Collaborators() {

  return (
    <Wrapper id="contact" className="container flexSpaceCenter">
      <div className="whiteBg">
        <div className="container" style={{ paddingTop: "30px" }}>
          <HeaderInfo>
            <h1 className="font40 extraBold">Join us, grow together!</h1>
            <p className="font20">
              We welcome all the service providers and integrators to grow together with Aissist. 
            </p>
            <p className="font20">
              <a href="/contact" className="greenColor font18">Contact us</a> today for details.
            </p>
          </HeaderInfo>
          <img className="radius6" src={CollaboratorsImage} alt="Collaborators Program" />
          <p className="font20">
            Aissist.io is commited to grow with community  
          </p>
        </div>
        <div style={{ paddingBottom: "100px" }}>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin-top: 50px;
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #048219;
  background-color: #048219;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #f57c00;
    border: 1px solid #f57c00;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
