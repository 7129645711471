import emailjs from 'emailjs-com';

const SERVICE_ID = "service_1mqrf3x"
const PUBLIC_KEY = "7ktsfCvaFGxPdNiNY"
const DEFAULT_TEMPLATE_ID = "template_ntgzgsb"

export function sendEmail(params, templateId, onSuccess, onError){

    var _templateId_ = templateId
    if (_templateId_ == null){
        _templateId_ = DEFAULT_TEMPLATE_ID
    }

    emailjs.send(SERVICE_ID, _templateId_, params, PUBLIC_KEY)
    .then((result) => {
        const text = result.text
        if (onSuccess != null){
            onSuccess(text)
        }
    }).catch(function(error) {
        // console.error('Error sending email: ', error);
        if (onError != null){
            onError(error)
        }
    });
}