import React from "react";
import styled from "styled-components";
// Components

// Assets
import Sorting from "src/assets/img/product/sorting.png"
import Speed from "src/assets/img/product/speed.png"
import Help from "src/assets/img/product/help.png"
import Summarize from "src/assets/img/product/summarize.png"

import EmailNinja from "src/assets/img/product/email_ninja.png"

export default function Email() {
  return (
    <Wrapper id="technology" className="container">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo className="flexNullCenter">
            <img className="radius6" src={EmailNinja} alt="FrontApp" style={{height:"250px"}}/>
            <div>
              <h1 className="font40 extraBold">Email Ninja</h1>
              <p className="font25">
                Don't let your team drowned in the piles of emails
              </p>
            </div>
          </HeaderInfo>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Sort and prioritize emails to keep your team organized and efficient</h2>
              <p className="font20">
                Is your business buried in piles of emails and don't know how to sort and prioritize them? Aissistant, with smart tagger technology, helps you sort the email threads and distribute to the right teams automatically. 
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={Sorting} alt="sorting" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Improve your response speed and customer satisfaction</h2>
              <p className="font20">
                Are your customers complaining about your slow response? Aissistant generates response that is welcoming, human-like, intelligent, customized to each engagement with rich context and knowledge from the assets provided.
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={Speed} alt="response speed" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Create draft to improve your team's productivity</h2>
              <p className="font20">
                Are your team tired of replying the same topic repetitively? Aissistant can also generate drafts for human team to review, modify then send. More than 60% of drafts can be directly sent without modification. 
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={Help} alt="help" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Automatically summarize next steps</h2>
              <p className="font20">
                Is your team productivity suffering from constant context switching? Aissistant generates summary of context as well as suggested next steps to bring the human team up to speed quickly.
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={Summarize} alt="Summarize" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin-top: 150px;
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 10px 0;
  margin: 10px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 50px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddTextLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddTextRight = styled.div`
  position: relative;
  width: 40%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddImageLeft = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddImageRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 1% 1%;
  img {
    max-width: 100%;
    height: 350px;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
