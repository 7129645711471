import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import LaunchImage from "../../assets/img/product/launch.png"

import BoostProductivityImage from "../../assets/img/product/boost_productivity.png"
import MagicImage from "../../assets/img/product/magic.png"
import CollaborationImage from "../../assets/img/product/collaboration.png"
import HappyCustomerImage from "../../assets/img/product/happy_customers.png"
import ConnectedImage from "../../assets/img/product/connected.png"
import EasyImage from "../../assets/img/product/easy.png"
import CheapImage from "../../assets/img/product/cheap.png"
import ConsoleSlideShow from "../../assets/img/product/console_slide_show.gif"

import { toggleConverseWidget } from "../Elements/ConverseWidget";

export default function Header() {

  return (
    <div className="whiteBg">
      <div className="container">
        <div style={{padding: '40px'}}/>
        <Wrapper id="home" className="container flexSpaceCenter">
          <LeftSide className="flexCenter">
            <div>
              <h1 className="greenColor extraBold font60">Aissistant</h1>
              <p className="font30">
                Fuel growth with Automation
              </p>
              <p className="font20">
                Generative AI that empowers fast-growing SMBs to scale rapidly and cost efficiently!
              </p>
              <div style={{ padding: "30px 0" }}>
                <a href="contact" className="font25 extraBold radius8 lightBg greenColor" style={{ padding: "10px 15px" }} target="_blank_">Request Demo</a>
              </div>
              {/*
              <div style={{ padding: "10px 0" }}>
                <FullButton 
                  title={"Try me"}
                  action={event => window.FrontChat("show")}>
                  Try Me
                </FullButton>
              </div>
              */}
            </div>
          </LeftSide>
          <RightSide>
            <ImageWrapper>
              <Img className="radius8" src={LaunchImage} alt="network" style={{zIndex: 9, opacity: 1.0}}/>
            </ImageWrapper>
          </RightSide>
        </Wrapper>
        <Wrapper id="home" className="container flexSpaceCenter" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <FeatureWrapper>
            <iframe width="100%" height="500" src="https://player.vimeo.com/video/912383735?title=0&amp;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Fuel Growth With Automation">
            </iframe>
            <script src="https://player.vimeo.com/api/player.js"></script>
            {/*
            <iframe width="800" height="500" src="https://www.youtube.com/embed/J0aBSPEMGWc" title="Aissist: Fuel Growth With Automation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            */}
          </FeatureWrapper>
        </Wrapper>
        <div style={{padding: '20px'}}/>
        <Wrapper className="container flexSpaceCenter">
          <HighlightWrapper style={{margin: "10px"}}>
            <h2 className="greenColor font30 extraBold">Automated Sales</h2>
            <h6 className="font25">Conversion rate higher than human</h6>
            <p className="font18">
              <b className="font20">4X </b>cost efficiency, high conversion, consistent performance, unlimited scalability and flexibility!
            </p>
            <ImageWrapper style={{width: '70%', alignItems: "center"}}>
              <SmImg className="radius8" src={BoostProductivityImage} alt="boost productivity" style={{zIndex: 9, opacity: 1.0}}/>
            </ImageWrapper>
          </HighlightWrapper>
          <HighlightWrapper style={{margin: "10px"}}>
            <h2 className="greenColor font30 extraBold">Automated Service</h2>
            <h6 className="font25">80% resolution rate</h6>
            <p className="font18">
              <b className="font20">60% </b>cost saving, happy customers, always there, highly responsive, productivity boost and no more worries on attrition!
            </p>
            <ImageWrapper style={{width: '70%', alignItems: "center"}}>
              <SmImg className="radius8" src={HappyCustomerImage} alt="happy customer" style={{zIndex: 9, opacity: 1.0}}/>
            </ImageWrapper>
          </HighlightWrapper>
        </Wrapper>
        <Wrapper className="container flexSpaceCenter" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <FeatureWrapper>
            <h1 className="font25 extraBold">Seamlessly work with human team to form a hybrid force</h1>
            <p className="font18">
              Assistant works on the same agent platform and functions just like a human, seamlessly integrating with your existing process without any disruptions!
            </p>
          </FeatureWrapper>
        </Wrapper>
        <Wrapper className="container flexSpaceCenter" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <FeatureWrapper>
            <h1 className="font25 extraBold">Auto-detect the need for human help and hand-over smoothly</h1>
            <p className="font18">
              Are you concerned about AI behaving improperly or failing to notice important information? Assistant is designed with self-awareness to detect when human assistance or attention is required and will promptly notify you.
            </p>
          </FeatureWrapper>
        </Wrapper>
        <Wrapper className="container flexSpaceCenter" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <FeatureWrapper>
            <h1 className="font25 extraBold">10 minutes to setup and tailored to each business</h1>
            <p className="font18">
              Why spend $$$$$ JUST on creating AI solutions when Aissistant can transform your assets and real-time information systems into exceptional AI in just 10 minutes at no cost?
            </p>
          </FeatureWrapper>
        </Wrapper>
        <Wrapper className="container flexSpaceCenter" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <FeatureWrapper>
            <h1 className="font25 extraBold">Omni-channel</h1>
            <p className="font18">
              Assistant operates on SMS, WhatsApp, Email, Online Form, Social, and WebChat!
            </p>
          </FeatureWrapper>
        </Wrapper>
        <div style={{padding: '20px'}}/>
        <Wrapper className="container flexSpaceCenter" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <ImageWrapper>
            <Img className="radius8" src={ConsoleSlideShow} alt="Console Slide Show" style={{zIndex: 9, opacity: 1.0}}/>
          </ImageWrapper>
        </Wrapper>
      </div>
    </div>
  );
}


const Wrapper = styled.section`
  padding-top: 30px;
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  padding: 10px;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  padding: 10px;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 20px;
  }
  @media (max-width: 560px) {
    margin: 30px 0 30px 0;
  }
`;
const FeatureWrapper = styled.div`
  margin: 10px;
  width: 80%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin: 20px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    width: 100%;
    margin: 30px 0 30px 0;
  }
`;
const HighlightWrapper = styled.div`
  margin: 10px;
  width: 50%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin: 20px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    width: 100%;
    margin: 30px 0 30px 0;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  width: 100%;
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const SmImg = styled.img`
  width: 90%;
  @media (max-width: 560px) {
    width: 60%;
    height: auto;
  }
`;
