import React from "react";
import styled from "styled-components";

export default function FullButton({ id, disabled, title, action }) {
  return (
    <Wrapper
      id={id}
      disabled={disabled}
      className="animate pointer radius8"
      onClick={action && disabled != true ? () => action() : null}
    >
      <h1 className="extraBold font14">{title}</h1>
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.disabled ? "#707070" : "#048219")};
  background-color: ${(props) => (props.disabled ? "transparent" : "#048219")};
  width: 100%;
  padding: 5px;
  margin: 5px;
  outline: none;
  color: ${(props) => (props.disabled ? "#707070" : "#fff")};
  :hover {
    background-color: ${(props) => (props.disabled ? "transparent" : "#f57c00")};
    border: 1px solid ${(props) => (props.disabled ? "#707070" : "#f57c00")};
    color: ${(props) => (props.disabled ? "#7620ff" : "#fff")};
  }
`;

