import React from "react";
import styled from "styled-components";
// Components

// Assets
import PilotModeImage from "src/assets/img/product/pilot_mode.png"

export default function PilotMode() {
  return (
    <Wrapper id="technology" className="container">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo className="flexNullCenter">
            <div>
              <h1 className="font40 extraBold">Auto-Pilot and Co-Pilot</h1>
              <p className="font25">
                80% of Aissistant users chose Auto-Pilot because it offers a game changer
              </p>
            </div>
          </HeaderInfo>
          <img className="radius6" src={PilotModeImage} alt="Pilot Mode" />
          <p className="font20">
            To change the setup of Aissistant, go to <a href="https://console.aissist.io/app/gateways" target="_blank">console.aissist.io</a>, then click tab "gateways".
          </p>
        </div>
        <div style={{ paddingBottom: "100px" }}></div>
      </div>
      
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin-top: 150px;
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 10px 0;
  margin: 10px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 50px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddTextLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddTextRight = styled.div`
  position: relative;
  width: 40%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddImageLeft = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddImageRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 1% 1%;
  img {
    max-width: 100%;
    height: 350px;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
