import React from "react";
import styled from "styled-components";
// Components

import FrontAppLogo from 'src/assets/img/vendors/frontapp.png'

// Assets
import Workflow from "src/assets/img/product/workflow.png"

import FrontSummary from "src/assets/img/vendors/front/front_summary.png"
import FrontReply from "src/assets/img/vendors/front/front_reply.png" 
import FrontTransfer from "src/assets/img/vendors/front/front_transfer.png"
import Assets from "src/assets/img/product/assets.png"
import DashboardAnalysis from "src/assets/img/product/dashboard_analysis.png"
import SmartTagger from "src/assets/img/product/smart_tagger.png"

export default function FrontApp() {
  return (
    <Wrapper id="technology" className="container">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo className="flexNullCenter">
            <div>
            <h1 className="font40 extraBold">FrontApp</h1>
            <img className="radius6" src={FrontAppLogo} alt="FrontApp" style={{height:"100px"}}/>
            </div>
            <p className="font25">
              Aissistant - your Business Co-Pilot in FrontApp
            </p>
          </HeaderInfo>
        </div>
      </div>
      <div className="whiteBg">
        <div className="container">
          <Advertising className="flexColumn">
            <div className="container">
              <h1 className="font25 extraBold">Streamline your customer engagement and improve your team productivity, today!</h1>
              <p className="font20">
                Recommended use of Aissistant
              </p>
              <img className="radius6" src={Workflow} alt="Onboard" />
            </div>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Turn your assets into intelligence</h2>
              <p className="font20">
                Aissistant can turn any of your assets into source of intelligence to boost productivity and save cost instantly!
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={Assets} alt="test" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Generate welcoming response like a human</h2>
              <p className="font20">
                Aissistant generates the response from conversational contexts, customer records and provided assets. If preferred, Aissistant can generate drafts for human team to review, confirm then send.
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={FrontReply} alt="test" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Auto-detect the need for human help and hand over smoothly</h2>
              <p className="font20">
                Aissistant automatically detects the needs for human to help and hand-over to human team via generated tags. 
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={FrontTransfer} alt="test" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Auto-summarize the context and next steps to bring human team up to the speed</h2>
              <p className="font20">
                During hand-over, Aissistant automatically summarizes the conversation and suggests next steps for the human team.
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={FrontSummary} alt="Turn your assets into productivity" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Smart taggers keep your inbox sorted and organized, easily!</h2>
              <p className="font20">
                Is your team drawned in piles of customer conversations? Tell aissistant, in natural language, how you want to manage your inbox, and let the AI do it for you.
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={SmartTagger} alt="Turn your assets into productivity" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
      <div>
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddTextLeft>
              <h2 className="font30 extraBold">Generate insights automatically!</h2>
              <p className="font20">
                Want to know what has happened in your customer engagements? Aissistant helps you generate insight whatever the way you want it, just tell AI. 
              </p>
            </AddTextLeft>
            <AddImageRight>
              <ImgWrapper className="flexCenter">
                <img className="radius6" src={DashboardAnalysis} alt="Turn your assets into productivity" />
              </ImgWrapper>
            </AddImageRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  margin-top: 150px;
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 20px 0;
  margin: 20px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 50px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddTextLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddTextRight = styled.div`
  position: relative;
  width: 40%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddImageLeft = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddImageRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 1% 1%;
  img {
    max-width: 100%;
    height: 350px;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
