import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper id="blog" >
      <div className="whiteBg" style={{ paddingTop: "100px" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">News</h1>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="New Office!"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="company"
                author="Luke Skywalker, 2 days ago"
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Feature Launch! "
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="company"
                author="Luke Skywalker, 2 days ago"
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Feature Launch!"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="company"
                author="Luke Skywalker, 2 days ago"
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Product Launch!"
                text="Aissistant is officially available on FrontApp for Beta users."
                tag="product"
                author="Rob Jiang, March 23, 2023"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: "100px" }}>
        </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;