
import LogoImage from "src/assets/logo.png";

const Logo = (props) => (
    <img
      alt="Logo"
      src={LogoImage} 
      width="140" 
      height="65"
      {...props}
    />
);
  
export default Logo;